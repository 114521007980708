// import axios from 'axios';
import axiosApi from '@/api/index';

let urlPath = '/api/postFile';

async function get(params) {
	return axiosApi.get(`${urlPath}`, { params });
}
async function count() {
	let res = await axiosApi.get(`${urlPath}/count`);
	let count = res.total;
	return count;
}
async function remove(id) {
	return axiosApi.delete(`${urlPath}/${id}`);
}

export default { get, count, remove };
