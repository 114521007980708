<template>
	<!-- container -->
	<div>
		<!-- titArea -->
		<div class="titArea">
			<div class="inner">
				<h2 class="txtL">커뮤니티 상세</h2>
				<a @click="communityList()" class="btnBack">커뮤니티 목록으로 이동</a>
				<div class="right">
					<a class="btnIcoWeite" v-if="mine" @click="update">수정</a>
					<a @click="onDelete()" class="btnTrash" v-if="mine">삭제</a>
				</div>
			</div>
		</div>
		<!--// titArea -->
		<!-- boxWhite -->
		<div class="boxWhite">
			<div class="inner">
				<!-- communityDetail -->
				<div class="communityDetail">
					<!-- detailTit -->
					<div class="detailTit">
						<span>{{ categoryName }}</span>
						<strong
							><pre v-if="title" style="font-family: 'Noto Sans KR', sans-serif;">{{
								title.replace(/ /g, '&nbsp;')
							}}</pre></strong
						>
						<div class="viewInfo">
							<div class="user">
								<div class="imgArea">
									<img v-if="creatorImageId" :src="`/file/${creatorImageId}`" alt="" />
								</div>
								<p>{{ creatorName }}</p>
							</div>
							<span class="btnViews">{{ views }}</span>
							<p class="date">
								<span>{{ createDate | dateFormatYear }}</span>
								<span>{{ createDate | dateFormatMonth }}</span>
							</p>
						</div>
					</div>
					<!--// detailTit -->
					<!-- detailCont -->
					<div class="detailCont">
						<p>
							<span>
								<pre v-if="content" style="font-family: 'Noto Sans KR', sans-serif;">{{
									content.replace(/ /g, '&nbsp;')
								}}</pre>
							</span>
						</p>
						<!-- imgList -->
						<div class="devImgList">
							<div class="inBox">
								<p v-for="(image, index) in images" :key="index">
									<img
										:src="`/file/${image.fileId}`"
										alt=""
										@click="onOpenImage(image.fileId)"
										style="cursor: pointer; "
									/>
								</p>
							</div>
						</div>
						<!--// imgList -->
					</div>
					<!--// detailCont -->
					<!-- attaArea -->
					<div class="attaArea">
						<p v-for="(file, index) in files" :key="index">
							<a :href="`/file/download/${file.fileId}`">{{ file.fileName }}</a>
						</p>
					</div>
					<!--// attaArea -->
				</div>
				<!--// communityDetail -->
			</div>
		</div>
		<!--// boxWhite -->
		<!-- contents -->
		<div class="contents">
			<!-- commentArea -->
			<div class="commentArea">
				<!-- viewInfo -->
				<div class="viewInfo">
					<a class="btnComment">{{ comment.total }}</a>
					<a class="btnViews">{{ comment.views }}</a>
				</div>
				<!--// viewInfo -->
				<!-- writeArea -->
				<div class="writeArea">
					<textarea
						title="댓글 입력"
						placeholder="댓글을 입력해주세요."
						v-model="comment.content"
						maxlength="254"
					></textarea>
					<div class="btnRight">
						<button type="button" class="btn ss" @click="onSaveComment()" :disabled="!saveBtn">등록하기</button>
					</div>
				</div>
				<!--// writeArea -->
				<!-- list -->
				<div class="list">
					<ul>
						<li class="memo" v-for="(comment, index) in commentList" :key="index">
							<div class="user">
								<div class="imgArea">
									<img v-if="comment.creatorImageId" :src="`/file/${comment.creatorImageId}`" alt="" />
								</div>
								<p>{{ comment.creatorName }}</p>
							</div>
							<p class="date">
								<span>{{ comment.createDate | dateFormatYear }}</span>
								<span>{{ comment.createDate | dateFormatMonth }}</span>
							</p>
							<p v-if="comment.editMode == false">
								<span>
									<pre v-if="comment.content" style="font-family: 'Noto Sans KR', sans-serif;">{{
										comment.content.replace(/ /g, '&nbsp;')
									}}</pre>
								</span>
							</p>
							<textarea
								v-else
								title="수정 댓글 입력"
								placeholder="수정할 댓글을 입력해주세요."
								v-model="updateComment"
								maxlength="254"
							></textarea>
							<div class="btnRight" v-if="userId === comment.creator">
								<button type="button" class="btn ss btnGrayL" @click="onUpdateComment(index)">
									수정하기
								</button>
								<button type="button" class="btn ss btnDGray" @click="onDeleteComment(comment.id)">
									삭제하기
								</button>
							</div>
						</li>
					</ul>
				</div>
				<!--// list -->
			</div>
			<!--// commentArea -->
		</div>
		<!--// contents -->
		<PopUpImage ref="popUpImage"></PopUpImage>
	</div>
	<!--// container -->
</template>
<script>
import post from '@/api/post';
import postComment from '@/api/postComment';
import postFile from '@/api/postFile';
import PopUpImage from '../popup/popUpImage';
import { showSpinner, hideSpinner, getAuth, dateFormatYear, dateFormatMonth } from '@/utils/cmm';

export default {
	components: { PopUpImage },
	created() {
		this.onGet();
		this.onGetComment();
	},
	mounted() {
		this.$EventBus.$on('paging', () => {
			if (this.comment.total <= this.comment.offset) return;
			this.onGetComment();
		});
	},
	beforeDestroy() {
		this.$EventBus.$off('paging');
	},
	data() {
		return {
			id: this.$route.params.id,
			searchKeyword: this.$route.params.searchKeyword,
			orderBy: this.$route.params.orderBy,
			category: '',
			categoryName: '',
			title: '',
			creator: '',
			creatorName: '',
			creatorImageId: '',
			content: '',
			views: '',
			createDate: '',
			images: [],
			files: [],
			mine: false,
			userId: getAuth().id,
			commentList: [],
			comment: {
				offset: 0,
				limit: 10,
				total: '',
				content: '',
			},
			updateComment: null,
		};
	},
	filters: {
		dateFormatYear,
		dateFormatMonth,
	},
	computed: {
		saveBtn: {
			get() {
				return this.comment.content.length > 0;
			},
			set() {},
		},
		content2: function() {
			return this.content.split('\n');
		},
	},
	methods: {
		communityList() {
			this.$router.push({
				name: 'communityList',
				params: { category: this.category, searchKeyword: this.searchKeyword, orderBy: this.orderBy },
			});
		},
		update() {
			this.$router.push({
				name: 'communityUpdate',
				params: { postId: this.id, category: this.category, searchKeyword: this.searchKeyword, orderBy: this.orderBy },
			});
		},
		async onGet() {
			try {
				showSpinner();
				let res = await post.getOne(this.id);
				this.category = res.category;
				this.categoryName = res.categoryName;
				this.title = res.title;
				this.content = res.content;
				this.creatorName = res.creatorName;
				this.creatorImageId = res.creatorImageId;
				this.views = res.views;
				this.createDate = res.createDate;
				this.mine = getAuth().id === res.creator;

				let params = {
					postId: this.id,
					type: 'I',
				};
				let imageRes = await postFile.get(params);
				if (imageRes.total > 0) this.images = imageRes.data;

				params.type = 'F';
				let fileRes = await postFile.get(params);
				if (fileRes.total > 0) this.files = fileRes.data;
			} catch (ex) {
				await this.$alert(`삭제된 게시글입니다.`);
				this.$router.go(-1);
			} finally {
				hideSpinner();
			}
		},
		async onDelete() {
			if (!(await this.$confirm('작성한 글을 삭제하시겠습니까?', '게시판 안내'))) return false;
			try {
				showSpinner();
				await post.remove(this.id);
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			} finally {
				hideSpinner();
			}
			this.communityList();
		},
		async onGetComment() {
			try {
				let params = {
					offset: this.comment.offset,
					limit: this.comment.limit,
				};
				let res = await postComment.get(this.id, params);
				this.comment.total = res.total;
				if (this.comment.total > 0) {
					res.data.forEach(c => {
						c.editMode = false;
						this.commentList.push(c);
					});
					this.comment.offset += this.comment.limit;
				}
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			}
		},
		async onSaveComment() {
			let params = {
				postId: this.id,
				content: this.comment.content,
			};
			try {
				showSpinner();
				await postComment.create(params);
				this.comment.content = '';
				this.commentList = [];
				this.comment.offset = 0;
				this.onGetComment();
			} catch (ex) {
				await this.$alert(`${ex.message}`);
				if (ex.status == 2013) {
					this.moveBack();
				}
			} finally {
				hideSpinner();
			}
		},
		async onDeleteComment(id) {
			if (!(await this.$confirm('선택하신 댓글을 삭제하시겠습니까?', '게시판 안내'))) return false;
			try {
				showSpinner();
				await postComment.remove(id);
				this.commentList = [];
				this.comment.offset = 0;
				this.onGetComment();
			} catch (ex) {
				await this.$alert(`${ex.message}`);
				if (ex.status == 2013) {
					this.moveBack();
				}
			} finally {
				hideSpinner();
			}
		},
		async onUpdateComment(index) {
			if (this.commentList[index].editMode == false) {
				this.commentList.forEach(c => (c.editMode = false));
				this.commentList[index].editMode = true;
				this.updateComment = this.commentList[index].content;
			} else {
				try {
					showSpinner();
					let params = {
						id: this.commentList[index].id,
						content: this.updateComment,
					};
					await postComment.update(params);
					this.commentList[index].editMode = false;
					this.commentList[index].content = this.updateComment;
				} catch (ex) {
					await this.$alert(`${ex.message}`);
					if (ex.status == 2013) {
						this.moveBack();
					}
				} finally {
					hideSpinner();
				}
			}
		},
		onOpenImage(imageId) {
			this.$refs.popUpImage.onOpenImage(imageId, this.images);
		},
	},
};
</script>
