// import axios from 'axios';
import axiosApi from '@/api/index';

let urlPath = '/api/postComment';

async function get(postId, params) {
	return axiosApi.get(`${urlPath}/${postId}`, { params });
}
async function create(params) {
	return axiosApi.post(`${urlPath}`, params);
}
async function update(params) {
	return axiosApi.put(`${urlPath}`, params);
}
async function remove(id) {
	return axiosApi.delete(`${urlPath}/${id}`);
}

export default { get, create, update, remove };
